import styled from "styled-components";
import Icon from "./Icon";

const Navbar: React.FC = () => {
	return (
		<Nav className="z-20 bg-black sm:bg-transparent sticky top-0 flex sm:flex-row flex-col items-center justify-between text-white sm:pl-2 sm:pr-6 pt-1 pb-6" style={{width:"fit-content"}}>
			<a href="https://magiceden.io/marketplace/ka">
				<Icon icon="logo" width={120} height={80} color={"white"}/>
			</a>
			{/* <div className="flex flex-wrap sm:justify-start justify-center md:mt-0 mt-4">
				<a href="https://latrodectus.io/">HOME</a>
				<a href="https://latrodectus.io/" className="mx-5">
					ABOUT
				</a>
				<a href="https://latrodectus.io/" className="mx-5">
					ROADMAP
				</a>
			</div> */}
		</Nav>
	);
};

const Nav = styled.nav`
	font-family: var(--font-family-2);

	& a {
		color: #fff !important;
	}

	@media screen and (max-width: 639px) {
		background-color: rgba(0, 0, 0, 0.1);
		-webkit-backdrop-filter: blur(10px);
		backdrop-filter: blur(10px);
	}
`;

export default Navbar;
