import * as web3 from '@solana/web3.js';

export const FIRST_CREATOR_IS_LAST_INDEX = false ;//false; // change to false for mainnet as it's the first index 
                                                // instead of last


export const NETWORK = "mainnet-beta";

export const programId : web3.PublicKey = 

new web3.PublicKey("G2DnhgAhP2AsdDt2Rc6od26EiAxBq3ZbAcZEvWQDB9s8");

// for $DAWG token 
export const DC_TOKEN_DECIMAL = 9; 

export const DC_TOKEN_MINT = "68RRPuZQrrw3whXHm9LSyC4y8iLrjkTm5Brc2tUMLNPw";

//"9QKHcZihXMdBgYdrWgqXCP6o6GWWb9ttsqS7WXSVuJZ8";

// This is the wallet address that created the $DAWG token vault
export const DC_TOKEN_VAULT_OWNER = "FYUyvNbxCoEPVb61yxg1VhCnBFfis6BGChUpHiWoZQkV";


// NFT token vault API URI
export const NFT_TOKEN_VAULT_API = "https://outbreakstaking.geishalabs.com/api/tokenvault?mint=";

export const NFT_TOKEN_VAULT_FILE_WALLET = "7b2k52q9tqw8PLnUDbTd7NkbHtypDPjHyyoCdL7H5s4N";

export const STAKE_DATA_API = "https://outbreakstaking.geishalabs.com/api/stakeData";


export const SIGN_IN_API = "https://outbreakstaking.geishalabs.com/api/signin";


export const SIGN_OUT_API = "https://outbreakstaking.geishalabs.com/api/signout";

// for DC NFT 
export const DC_UPDATE_AUTHORITY = "axHCiBHswrv5yHX1ix4eKkjCX5oLnrgQDoz6KstrRft";

export const DC_VALID_FIRST_CREATOR = "";
